<template>
  <div>
    <router-link to="/plan/tools" class="back-link">
      <Flexy><img :src="ArrowRightIcon" /> <p>{{t('Fasting Calculator')}}</p></Flexy>
    </router-link>
    <div class="container-m">
      <div class="boxes light">
        <Box>
          <div class="fasting">

            <div v-if="fasting" :class="['fasting-meta', fastingIsCompleted?'completed':'']">
                <div v-if="fastingIsCompleted">
                  <h3>{{t("Your fasting has been completed successfully")}}</h3>
                  <p>{{t("You can eat now")}}</p>
                </div>
                <div v-else>
                  <h3>{{t("Your fasting has been started")}}</h3>
                  <p>{{t("You can eat after {0} hours", fastingHours)}}</p>
                </div>
                <h2 @click="toggleDisplayTime">{{displayTimeToGo?'-'+formattedTimeToGo : formattedTimePassed}}</h2>

                <div class="buttons" v-if="displayStopFasting">
                  <button class="btn btn-primary" @click="stopFasting">{{t('Really Stop')}}</button>
                  <button class="btn" @click="toggleDisplayStopFasting">{{t('Cancel')}}</button>
                </div>
                <button v-else class="btn btn-primary" @click="toggleDisplayStopFasting">{{t('Stop Fasting')}}</button>
            </div>
            <div v-else class="fasting-meta">
              <p>{{t("To start fasting, click on the button below")}}</p>
              <button class="btn btn-primary" @click="startFasting">{{t('Start Fasting')}}</button>
            </div>

            <div class="svg-container">
              <svg id="svg" width="300" height="300" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle r="140" cx="150" cy="150" fill="transparent" stroke-dasharray="1" stroke-dashoffset="0"> </circle>
                <circle id="bar" r="140" cx="150" cy="150" fill="transparent" :stroke="frameStrokeColor" stroke-width="15"> </circle>
                <circle v-if="fasting" id="bar" r="140" cx="150" cy="150" fill="transparent" stroke="#d2d2d2" stroke-width="15" :stroke-dasharray="fastingDashArray" :stroke-dashoffset="completionPercentageRadius"> </circle>
                <circle v-if="fasting" id="bar" r="140" cx="150" cy="150" fill="transparent" :stroke="fastingStrokeColor" stroke-width="15" :stroke-dasharray="fastingDashArray" :stroke-dashoffset="fastingPercentageRadius" stroke-linecap="round"> </circle>
              </svg>
            </div>
          </div>
        </Box>

        <Box v-if="fastingHistory" class="fasting-history">
          <h3>{{t("Fasting History")}}</h3>
          <div class="fasting-history-items">
            <div v-for="(item,k) in fastingHistory" :key="k" :class="'fasting-history-item' + (historyDateCompleted(item)?' completed':'')">
              <h4>{{fastingDuration(item)}}</h4>
              <p>{{fastingHistoryDate(item)}}</p>
              <p>{{fastingHistoryTime(item)}}</p>
            </div>
          </div>

          <div v-if="displayClearFastingHistory" class="flexy centered">
            <a class="clear" @click="clearFastingHistory">{{t('Really Clear')}}</a>
            <a class="clear default" @click="toggleDisplayClearFastingHistory">{{t('Cancel')}}</a>
          </div>
          <a v-else class="clear" @click="toggleDisplayClearFastingHistory">{{t('Clear Fasting History')}}</a>
        </Box>

      </div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from "@/lib/fito";
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import Flexy from "@/components/ui/widgets/Flexy.vue";
import Box from "@/components/ui/widgets/Box.vue";

export default {
  name: "FastingCalculator",
  components: {Box, Flexy},
  data() {
    return {

      fastingHours: 16,
      totalHours: 24,

      offsetHours: 0,
      fastingHistory: null,

      ArrowRightIcon,
      fasting: false,
      fastingStartTime: null,
      fastingTimePassed: 0,
      fastingTimeDiff: 0,
      fastingTimeout: null,
      displayTimeToGo: false,
      displayStopFasting: false,
      displayClearFastingHistory: false,
    }
  },
  async mounted() {
    const login = await Fito().Login.safeCheckLogin();
    if (!login) await this.$router.push('/plan/login');



    this.setOffsetHours(login)

  },
  beforeDestroy() {
    clearTimeout(this.fastingTimeout);
  },

  computed: {

    formattedTimePassed() {
      let t = this.fastingTimeDiff;
      let h = Math.floor(t/3600);
      let m = Math.floor((t - h*3600)/60); if (m < 10) m = '0' + m;
      let s = t - h*3600 - m*60; if (s < 10) s = '0' + s;
      let res = '';
      if (h > 0) res += h + ':';
      res += m + ':' + s;
      return res;
    },

    formattedTimeToGo() {
      let t = Math.max(0, this.fastingHours * 3600 - this.fastingTimeDiff);
      let h = Math.floor(t/3600);
      let m = Math.floor((t - h*3600)/60); if (m < 10) m = '0' + m;
      let s = t - h*3600 - m*60; if (s < 10) s = '0' + s;
      let res = '';
      if (h > 0) res += h + ':';
      res += m + ':' + s;
      return res;
    },

    fastingPercentage() {
      let hours = this.totalHours;
      let seconds = hours * 3600;
      return this.fastingTimeDiff / seconds * 100;
    },

    completionPercentage() {
      return this.fastingHours / this.totalHours * 100;
    },

    completionPercentageRadius() {
      let percentage = Math.min(this.completionPercentage, 100);
      let radius = 140;
      let circumference = 2 * Math.PI * radius;
      return circumference - percentage / 100 * circumference;
    },

    fastingPercentageRadius() {
      let percentage = Math.min(this.fastingPercentage, 100);
      let radius = 140;
      let circumference = 2 * Math.PI * radius;
      return circumference - percentage / 100 * circumference;
    },
    fastingDashArray() {
      let radius = 140;
      return 2 * Math.PI * radius;
    },
    fastingIsCompleted() {
      if (!this.fasting) return false;
      let hoursPassed = (this.fastingTimeDiff / 3600);
      return hoursPassed >= this.fastingHours;
    },
    fastingStrokeColor() {
      return this.fastingIsCompleted ? '#1dab3e':'#2baa9b';
    },
    frameStrokeColor() {
      return this.fastingIsCompleted ? '#c1eab7':'#eaeaea';
    }
  },
  methods: {
    t,

    setOffsetHours(login) {
        let t = new Date(), serverT = new Date(login.t);
        let diff = Math.floor((t - serverT)/1000);
        this.offsetHours = Math.round(diff / 3600);
        console.log(this.offsetHours,"offsetHours")
        this.resetFasting();
    },

    async clearFastingHistory() {
      this.fastingHistory = null;
      await Fito().RunAction('clear-fasting-history');
    },

    fastingDuration(historyItem) {
      let {start, now} = historyItem;
      let t = new Date(now), f = new Date(start.replace(/ /g,"T"));
      let diff = Math.floor((t - f)/1000);

      let hours = parseFloat((diff / 3600).toFixed(1));
      return this.t("{0} hours", hours);
    },

    fastingHistoryDate(historyItem) {
      let {start} = historyItem;
      let f = new Date(start.replace(/ /g,"T"));
      f.setTime(f.getTime() + this.offsetHours * 60 * 60 * 1000);
      let day = f.getDate();
      let month = f.getMonth() + 1;
      let year = f.getFullYear();


      return this.t("{0}.{1}.{2}", day, month, year);
    },

    fastingHistoryTime(historyItem) {
      let {start} = historyItem;
      let f = new Date(start.replace(/ /g,"T"));
      f.setTime(f.getTime() + this.offsetHours * 60 * 60 * 1000);
      let hours = f.getHours(); if (hours < 10) hours = '0' + hours;
      let minutes = f.getMinutes(); if (minutes < 10) minutes = '0' + minutes;

      return this.t("{0}:{1}", hours, minutes);
    },

    historyDateCompleted(historyItem) {
      let {start, now} = historyItem;
      let t = new Date(now.replace(/ /g,"T")), f = new Date(start.replace(/ /g,"T"));
      let diff = Math.floor((t - f)/1000);
      let hours = parseFloat((diff / 3600).toFixed(1));
      return hours >= this.fastingHours;
    },

    toggleDisplayClearFastingHistory() {
      this.displayClearFastingHistory = !this.displayClearFastingHistory;
    },

    toggleDisplayTime() {
      this.displayTimeToGo = !this.displayTimeToGo;
    },

    toggleDisplayStopFasting() {
      this.displayStopFasting = !this.displayStopFasting;
    },

    async resetFasting() {
      const login = await Fito().Login.safeCheckLogin(true);

      if (login.meta.fasting) {
        let t = new Date(login.t.replace(/ /g,"T")), f = new Date(login.meta.fasting.replace(/ /g,"T"));
        this.fastingTimePassed = Math.floor((t - f)/1000);
        this.fastingTimeDiff = this.fastingTimePassed;
        this.fasting = true;
        this.fastingStartTime = new Date();
        this.tick();
      }

      if (login.meta['fasting-history']) {
        this.fastingHistory = login.meta['fasting-history'].sort((a, b) => {
          return new Date(b.start.replace(/ /g,"T")) - new Date(a.start.replace(/ /g,"T"));
        });
      }
    },

    tick() {
      if (Math.random() > 0.9) return this.resetFasting();
      this.fastingTimeDiff = Math.floor((new Date() - this.fastingStartTime)/1000) + this.fastingTimePassed;
      this.fastingTimeout = setTimeout(this.tick, 1000);
    },

    async startFasting() {
      this.fastingTimeDiff = 0;
      this.fasting = true;
      await Fito().RunAction('start-fasting');
      this.resetFasting()
    },

    async stopFasting() {
      this.fasting = false;
      this.fastingStartTime = null;
      this.fastingTimePassed = 0;
      this.fastingTimeDiff = 0;
      this.displayStopFasting = false;

      await Fito().RunAction('stop-fasting');
      this.resetFasting();
      clearTimeout(this.fastingTimeout);
    }
  }
}
</script>
