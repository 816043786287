<template>
  <div id="app">
    <div :class="viewClass" v-if="!BUSY_MOCKUP">
      <router-view @stats="onStats" :stats="stats" :meta="meta" @meta="onMeta" @login="onLogin" @logout="onLogout" />
    </div>
    <SiteBusy v-if="BUSY_MOCKUP" />
  </div>
</template>

<script>

import SiteBusy from "@/components/views/common/SiteBusy";
const BUSY_MOCKUP = false;

import 'hooper/dist/hooper.css';

import './scss/style.scss';
import Fito from "@/lib/fito";
export default {
  name: 'App',
  components: {SiteBusy,   },
  data() {
    return {
      BUSY_MOCKUP,
      stats: null,
      loggedIn: false,
      meta : {},
      subscriber : null
    }
  },
  async mounted() {

    Fito().Login.onLogin(this.onLogin)
    Fito().Login.onLogout(this.onLogout)

    if (window.location.href.indexOf('secret-special') > -1) {
      localStorage.setItem('secret-special', Math.round(new Date().getTime()/1000)+'')
    }

  },
  computed : {
    currentRoute() { return this.$route.path },
    isLanding() { return this.currentRoute === '/'},
    viewClass() {
      return 'view'
          + (this.loggedIn? ' view-logged-in':'')
          + (this.isLanding? ' view-landing':'')
          + (this.$route.meta && this.$route.meta.form ? ' form':'')
    }
  },
  methods : {
    onStats(stats) {
      this.stats = stats
    },

    onMeta(key, value) {
      this.meta[key] = value
    },

    onLogin(login = null, isLoginAndNotHashCheck = false) {
      const wasLoggedIn = this.loggedIn
      if (login) {
        this.loggedIn = true
        this.subscriber = login.subscriber
        this.meta = login.meta

        if (!wasLoggedIn && isLoginAndNotHashCheck) Fito().Plan.Log('login')
        else if (!wasLoggedIn) Fito().Plan.Log('reload')
      }
    },

    onLogout() {
      this.loggedIn = false
      localStorage.clear()
      this.$router.push('/plan/login')
    }
  }
}
</script>
