<template>
  <div></div>
</template>

<script>

export default {
name: "Landing",
  async mounted() {
    window.location.href = "https://eatwise.co.il"
  },

}
</script>
