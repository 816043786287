import {ApiCall, ApiRequest} from './lib/Api';
import ApiRoutes from "./lib/ApiRoutes";

import login from './modules/login'
import signup from './modules/signup'
import upgrade from './modules/upgrade'
import contact from './modules/contact'
import landing from './modules/landing'
import plan from './modules/plan'

export default class Fito {

    constructor(apiKey, hostname) {
        this.ApiKey = apiKey;
        this.hostname = hostname;
        this.loggedIn = false;
        this.token = null;
        this.expired = false;
    }

    async initModules() {
        this.Login = new login(this)
        this.Landing = new landing(this)
        this.Signup = new signup(this)
        this.Upgrade = new upgrade(this)
        this.Contact = new contact(this)
        this.Plan = new plan(this)
    }

    async RunAction(action, params = {}, getDataAndAgeArray = false) {
        if (typeof params === 'boolean' && getDataAndAgeArray === false) {
            params = {}; getDataAndAgeArray = true;
        }
        const res = await ApiRequest(ApiCall(this.hostname, ApiRoutes.runAction, params, action), this.ApiKey, this.token)
        return res
    }

    async initApi(apiKey, currentVersion = null, onOldVersion = null, reloadOnceToFixMinimumVersion = true) {
        const r = await ApiRequest(ApiCall(this.hostname, ApiRoutes.checkApi), apiKey)
        if (r.res === 'ok') {
            this.brandId = r.brand_id
            await this.initModules();

            if (r.minv && currentVersion) {
                if (currentVersion.toString() < r.minv.toString()) {
                    if (reloadOnceToFixMinimumVersion) {
                        const reloadedOnce = localStorage.getItem('reloaded-once')
                        if (reloadedOnce) {
                            if (onOldVersion) {
                                this.inited = false
                                onOldVersion()
                                throw('Client version is too old');
                            }
                        }
                        else {
                            localStorage.setItem('reloaded-once', "1")
                            window.location.reload()
                        }
                    }
                    else if (onOldVersion) {
                        throw onOldVersion()
                    }
                }
            }
            this.inited = true
        }
    }
}
