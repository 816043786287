<template>
  <div class="panel-form">
    <app-form :on-submit="trySubmit">
      <InputWrapper v-if="stage === 'email'" v-model="email" type="email" :label="t('Email')" :placeholder="t('E-mail Address')" />
      <InputWrapper v-if="stage === 'password'" v-model="password" type="password" :label="t('Password')" :placeholder="t('Password (at least 8 letters)')" />
      <div class="between" v-if="stage === 'password'">
        <p><input type="checkbox" v-model="remember" /> {{t('Remember Me')}}</p>
        <a href="javascript:void(0)" @click="forgot" class="forgot-password">{{t('Forgot Password')}}</a>
      </div>
      <div class="success" v-if="stage === 'activation'">
        <div class="success-body">
          <b>{{t('Confirm account activation')}}</b>
          <p>{{t('Please check your e-mail and click the activation link to complete your registration')}}</p>
        </div>
      </div>

      <div v-if="error" class="error">
        <b>{{t('Error')}}</b>
        <div class="error-body">{{t(error)}}</div>
      </div>
      <div class="login-buttons" v-if="stage !== 'activation'">
        <button @click="trySubmit" tabindex="3" :class="'btn 3'+(canSubmit?' active':' disabled')">{{t('Sign me in')}}</button>
        <button tabindex="3" class="btn secondary" ref="googleLogin" v-if="stage==='email'"><img :src="GoogleIcon" /> {{t('Sign in with Google')}}</button>
        <button tabindex="3" class="btn secondary" ref="facebookLogin" v-if="showFb && stage==='email'" @click="facebookLogin"><img :src="FacebookIcon" /> {{t('Sign in with Facebook')}}</button>
      </div>
    </app-form>
  </div>
</template>

<script>
import AppForm from "@/components/forms/AppForm";
import CheckIcon from "@/assets/icons/check.svg";
import GoogleIcon from "@/assets/icons/external/google.svg";
import FacebookIcon from "@/assets/icons/external/facebook.svg";
import t from "@/lib/Locale";
import InputWrapper from "@/components/ui/widgets/InputWrapper";
import jwtDecode from 'jwt-decode';
import {getThirdPartyHash} from "@/lib/ThirdPartyAuth";

export default {
name: "LoginForm",
  props : ['onSubmit', 'onForgot', 'error','stage'],
  components: {InputWrapper, AppForm },

  data : function() {
    return {
      CheckIcon,
      GoogleIcon,
      FacebookIcon,

      showEmail: true,
      showPassword: false,
      showFb: false,

      email: '',
      password: '',
      remember: false,
    }
  },


  mounted() {
      this.setupGoogle()
      if (this.$route.query &&this.$route.query.fb) {
        this.showFb = true
        setTimeout(() => this.setupFacebook(), 500)
      }
  },

  methods : {
    t,

    handleCredentialResponse(a) {
      if (!a.credential) {
        console.log("No credential!", a)
        return false
      }
      let credential = jwtDecode(a.credential)
      let id = credential.sub,
          email = credential.email;

      if (this.onSubmit) {
        this.onSubmit(email, getThirdPartyHash(id), id)
      }
    },

    setupGoogle() {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: "815340122877-jm2heklkbihdsf67le2hqjmt78jvgccl.apps.googleusercontent.com",
          callback: this.handleCredentialResponse,
        })
        window.google.accounts.id.prompt()
        window.google.accounts.id.renderButton(this.$refs.googleLogin, {
          theme: 'outline',
          size: 'large',
        })
      }
    },

    setupFacebook() {

        window.FB.init({
          appId      : '489981449233462',
          cookie     : true,                     // Enable cookies to allow the server to access the session.
          xfbml      : true,                     // Parse social plugins on this webpage.
          version    : 'v13.0'           // Use this Graph API version for this call.
        });


        window.FB.getLoginStatus(function(response) {   // Called after the JS SDK has been initialized.
          console.log(response);        // Returns the login status.
        });
    },

    isValid(val, type) {
      switch (type) {
        case 'email': return val.indexOf('@') > 0 && val.lastIndexOf(".") > val.lastIndexOf('@') && val.lastIndexOf(".") < val.length-2;
        case 'password': return val.length >= 8;
      }
      return false;
    },

    facebookLogin() {
      console.log("FB Login?")
      window.FB.login(function(response){
        console.log("FB Response", response)
      });
    },

    trySubmit() {
      if (this.canSubmit) {
        if (this.stage === 'password')
          this.onSubmit(this.email, this.password);
        else this.onSubmit(this.email);
      }

    },

    forgot : function() {
      if (this.onForgot) this.onForgot();
    }
  },

  computed : {
    canSubmit : function() {
      return (this.stage === 'email' && this.isValid(this.email, 'email')) ||
          (this.stage === 'password' && this.isValid(this.password, 'password'))
    },
  },
}
</script>
