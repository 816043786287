<template>
  <div>
    <router-link to="/plan/tools" class="back-link">
      <Flexy><img :src="ArrowRightIcon" /> <p>{{t('Info Slides')}}</p></Flexy>
    </router-link>
    <div class="slides">
      <div v-for="(slide, index) in validTips" :key="index" class="slide">
        <img :src="slide" alt="slide" @click="selectedSlide = slide" />
      </div>

      <div class="slide-overlay" v-if="selectedSlide">
        <div class="slide-overlay-inner">
          <img class="slide-img" :src="selectedSlide" alt="slide" />
          <div class="slide-overlay-close" @click="selectedSlide = null">
            <img :src="CloseIcon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import CloseIcon from "@/assets/icons/x.svg"
import healthFactors from "@/assets/images/info-slides/2.png";
import foodsTable from "@/assets/images/info-slides/7.png";
import mealStructure from "@/assets/images/info-slides/8.png";
import caloricDensity from "@/assets/images/info-slides/12.png";
import antioxidants from "@/assets/images/info-slides/17.png";
import vegetablesChallenge from "@/assets/images/info-slides/18.png";
import proteinsTable from "@/assets/images/info-slides/14.png";
import sprouting from "@/assets/images/info-slides/hashraya.png";
import toxicCleanup1 from "@/assets/images/info-slides/toxic1.png";
import toxicCleanup2 from "@/assets/images/info-slides/toxic2.png";
import energyTanks from "@/assets/images/info-slides/energy-tanks.png";
import calorieIssues from "@/assets/images/info-slides/calorie-issues.png";

import ArrowRightIcon from '@/assets/icons/arrow-right.svg';

import Fito from "@/lib/fito";
import Flexy from "@/components/ui/widgets/Flexy.vue";


export default {
  name: "InfoSlides",
  components: {Flexy},
  data() {
    return {
      CloseIcon, ArrowRightIcon,
      items: [
          {slide: healthFactors, day: 0},
          {slide: foodsTable, day: 2},
          {slide: mealStructure, day: 3},
          {slide: antioxidants, day: 16},
          {slide: calorieIssues, day: 22},
          {slide: caloricDensity, day: 23},
          {slide: vegetablesChallenge, day: 23},
          {slide: sprouting, day: 30},
          {slide: proteinsTable, day: 37},
          {slide: toxicCleanup1, day: 46},
          {slide: toxicCleanup2, day: 46},
          {slide: energyTanks, day: 51},
      ],
      selectedSlide: null,
      daysInPlan: -1,
    }
  },
  async mounted() {
    const login = await Fito().Login.safeCheckLogin();
    if (!login) await this.$router.push('/plan/login');

    this.daysInPlan = login.daysInPlan
  },
  computed: {
    validTips() {
      let slides = []
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].day <= this.daysInPlan) {
          slides.push(this.items[i].slide)
        }
      }
      return slides
    }
  },
  methods: {
    t,
  }
}
</script>
