import axios from 'axios'
import papa from 'papaparse'

let quizData = null;
async function loadQuiz() {
    const csv = await axios.get('/quizdata.csv?'+Math.floor(new Date().getTime()/1000))
    let data = papa.parse(csv.data).data

    const columns = data.shift();
    return data.map(row => {
        let x = {};
        for (let i = 0; i < row.length; i++) {
            let v = row[i]
            if (v.indexOf("/") > -1) {
                if (columns[i] === "options") v = v.split("/").map(it => it.trim())
            }
            if (v.length > 0 && !isNaN(v)) v *= 1
            if (v === "Yes" || v === "true") v = true
            if (v === "No" || v === "false") v = false
            x[columns[i].toLowerCase()] = v;
        }
        return x
    })
}

export async function loadTasks(maxDay = 999) {
    const csv = await axios.get('/tasks.csv?'+Math.floor(new Date().getTime()/1000))
    let data = papa.parse(csv.data).data

    const columns = data.shift();
    return data.map(row => {
        let x = {};
        for (let i = 0; i < row.length; i++) {
            let v = row[i]
            if (columns[i].toLowerCase() === "day") v *= 1
            x[columns[i].toLowerCase()] = v;
        }
        return x
    }).filter(it => it.day*1 <= maxDay).sort((a,b) => a.day > b.day ? -1 : 1)
}

export async function getDailyQuiz(day) {
    if (!quizData) quizData = await loadQuiz()
    return quizData.filter(it => it.day*1 === day)
}

export async function getQuizDays() {
    if (!quizData) quizData = await loadQuiz()
    let days = {}
    for (let d of quizData) days[d.day*1] = true
    return Object.keys(days).map(it => it*1)
}
