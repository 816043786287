<template>
  <div>
    <div class="container-m">
      <div class="centered">{{t("Watch now: Introduction video")}}</div>
      <Vimeo path="806717268" />
    </div>
  </div>
</template>

<script>
/**
 * Available to all, in case they haven't watched for some reason
 */
import Vimeo from "@/components/ui/widgets/Vimeo.vue";
import t from '@/lib/Locale';
export default {
  name: "FeedHome",
  components: { Vimeo},
  methods: { t }

}
</script>
