<template>
  <div class="container-m">
    <div class="centered">
      <div class="header-logo small"><img :src="Logo" /></div>
      <h2>{{t('Activate Account')}}</h2>
    </div>
    <div class="panel" v-if="confirmed && !success">
      <h3>{{t("Please enter your new password")}}</h3>
      <p>{{t("The password must be at least 8 characters long")}}</p>
      <InputWrapper v-model="newPassword" :placeholder="t('New Password')" type="password" />
      <InputWrapper v-model="newPassword2" :placeholder="t('New Password (again)')" type="password2" :pass1="newPassword" />
      <label>
        <button :class="'btn'+(canSubmit?'':' disabled')" @click="resetPassword">{{t("Activate Account")}}</button>
      </label>
    </div>

    <div class="panel" v-if="success">
      <h3>{{t("Account activated successfully")}}</h3>
      <p>{{t("We are forwarding you to the login page")}}</p>
    </div>

    <div class="error" v-if="error">
      <h2>{{t("Error")}}</h2>
      {{t(error)}}
    </div>
  </div>
</template>

<script>

import t from "@/lib/Locale";
import Fito from '@/lib/fito';
import Logo from '@/assets/icons/lotus-white.png';
import InputWrapper from "@/components/ui/widgets/InputWrapper";

export default {
  name: "ActivateAccount",
  components: { InputWrapper },
  props: ['meta'],

  data() {
    return {
      t,
      Logo,
      newPassword: '',
      newPassword2: '',
      confirmed: false,
      error: '',
      success: false
    }
  },

  mounted() {
    this.confirmRecoveryCode();
  },

  methods : {
    async confirmRecoveryCode() {
      const recovery_code = this.$route.params.code;
      const {res, error} = await Fito().Login.ConfirmRecoveryCode(recovery_code);
      if (res === 'ok') {
        this.confirmed = true
      }
      else if (error) {
        this.error = error
      }
    },

    async resetPassword() {
      if (!this.canSubmit) return false;

      const recovery_code = this.$route.params.code;
      const {res, error} = await Fito().Login.ResetPassword(recovery_code, this.newPassword);
      if (res === 'ok') {
        this.success = true
        setTimeout(() => {
          window.location.href = "/app"
        }, 2000)
      }
      else if (error) {
        this.error = error
      }
    }
  },

  computed : {
    canSubmit() {
      return this.newPassword.length >= 8 && this.newPassword === this.newPassword2
    }
  }
}
</script>
