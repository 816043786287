<template>
  <component :is="tagName" :to="to" :href="link" :class="clsName" @click="click">
    <i v-if="icon" :class="icon"></i>
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: "Btn",
  props : {
    link : { type: String, },
    to: { type: String, },
    disabled : { type: Boolean, },
    type : { type: String, },
    icon : { type: String, },
  },
  computed : {
    tagName() {
      if (this.to) return 'router-link'
      else if (this.link) return 'a'
      else return 'button'
    },

    clsName() {
      let classes = ['btn']
      if (this.type) classes.push('btn-' + this.type)
      if (this.disabled) classes.push('disabled')
      return classes
    }
  },
  methods : {
    click() {
      this.$emit('click')
    },
  }
}
</script>
