<template>
  <div>
    <router-link to="/plan/bonus" class="back-link">
      <Flexy><img :src="ArrowRightIcon" /> <p>{{t('Recipes')}}</p></Flexy>
    </router-link>
    <div class="container-m">
      <div class="slides">
        <div v-for="(recipe, index) in validRecipes" :key="index" class="slide">
          <img :src="recipe" alt="recipe" @click="selectedRecipe = recipe" />
        </div>
        <div class="slide-overlay" v-if="selectedRecipe">
          <div class="slide-overlay-inner">
            <img class="slide-img" :src="selectedRecipe" alt="recipe" />
            <div class="slide-overlay-close" @click="selectedRecipe = null">
              <img :src="CloseIcon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from "@/lib/fito";
import CloseIcon from "@/assets/icons/x.svg"
import recipe1 from "@/assets/images/recipes/r1.jpg";
import recipe2 from "@/assets/images/recipes/r2.jpg";
import recipe3 from "@/assets/images/recipes/r3.jpg";
import recipe4 from "@/assets/images/recipes/r4.jpg";
import recipe5 from "@/assets/images/recipes/r5.jpg";
import recipe6 from "@/assets/images/recipes/r6.jpg";
import recipe7 from "@/assets/images/recipes/r7.jpg";
import recipe8 from "@/assets/images/recipes/r8.jpg";
import recipe9 from "@/assets/images/recipes/r9.jpg";
import recipe10 from "@/assets/images/recipes/r10.jpg";
import recipe11 from "@/assets/images/recipes/r11.jpg";
import recipe12 from "@/assets/images/recipes/r12.jpg";
import recipe13 from "@/assets/images/recipes/r13.jpg";
import recipe14 from "@/assets/images/recipes/r14.jpg";
import recipe15 from "@/assets/images/recipes/r15.jpg";
import recipe16 from "@/assets/images/recipes/r16.jpg";
import recipe17 from "@/assets/images/recipes/r17.png";
import recipe18a from "@/assets/images/recipes/r18a.jpg";
import recipe18b from "@/assets/images/recipes/r18b.jpg";

import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import Flexy from "@/components/ui/widgets/Flexy.vue";

export default {
  name: "RecipesHome",
  components: {Flexy},
  data() {
    return {
      CloseIcon, ArrowRightIcon,
      recipes : [
          recipe1, recipe2, recipe3, recipe4, recipe5, recipe6,
          recipe7, recipe8, recipe9, recipe10, recipe11, recipe12,
          recipe13, recipe14, recipe15, recipe16, recipe17,
          recipe18a, recipe18b,
      ],
      restrictionDays: [
          0, 2, 3, 17, 25, 8
      ],
      selectedRecipe: null,
      daysInPlan: -1,
    }
  },
  async mounted() {
    const login = await Fito().Login.safeCheckLogin();
    if (!login) await this.$router.push('/plan/login');

    this.daysInPlan = login.daysInPlan
  },
  computed: {
    validRecipes() {
      let recipes = []
      for (let i = 0; i < this.recipes.length; i++) {
        // if (this.restrictionDays[i] > this.daysInPlan) continue;
        recipes.push(this.recipes[i])
      }
      return recipes
    }
  },
  methods: {
    t,
  }
}
</script>
