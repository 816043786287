<template>
  <div class="container-m">
    <div class="box">
      <div class="centered">
      <h1>{{t('Passing you to signup and payment form')}}</h1>
      <PoweredBy />
    </div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import PoweredBy from "@/components/ui/widgets/PoweredBy.vue";
const paymentFormUrl = 'https://secure.cardcom.solutions/e/jklgyfoSMk0XLm0wn68fA';

export default {
  name: "Signup",
  props: ["meta"],
  components: {PoweredBy, },
  data() {
    return {
      paymentFormUrl,
    }
  },

  async mounted() {
    setTimeout(() => window.location.href = this.paymentFormUrl, 1000)
  },

  methods : {
    t,
  }

}
</script>
