<template>
  <div>
    <div class="boxes light purple">
      <Box :title="list.display_name" :thumb="list.thumbnail" :link="'/plan/bonus/yoga/'+list.name" v-for="(list, index) in lists" :key="index">
        <p>{{list.display_content}}</p>
      </Box>
      <Box :title="t('Recipes Book')" :thumb="RecipesIcon" link="/plan/bonus/recipes">{{t("Special recipes for the plan")}}</Box>
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Box from "@/components/ui/widgets/Box";
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import ExclusivesIcon from '@/assets/icons/menu/exclusives.svg';
import PeriodIcon from '@/assets/icons/menu/woman.svg';
import RecipesIcon from '@/assets/icons/menu/recipes.svg';
import FastingIcon from '@/assets/icons/menu/yoga.svg';

import Fito from "@/lib/fito";

export default {
  name: "BonusHome",
  props: ["meta"],
  components: {Box},
  data() {
    return {
      ArrowRightIcon, ExclusivesIcon, RecipesIcon, PeriodIcon, FastingIcon,
      lists: []
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')

    const mediaLists = await Fito().Plan.getMediaLists()
    const lists = mediaLists.sort((a,b) => a.sort < b.sort ? -1:1);
    if (lists.length > 0) {
      this.lists = lists;
    }

  },

  methods : {
    t,
  },

  watch : {
  }

}
</script>
