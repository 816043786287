<template>
  <div class="container">
    <ChoosePlanForm v-if="plans.length" :plans="plans" :on-select="onChoosePlan" />
  </div>

</template>

<script>
import ChoosePlanForm from "@/components/forms/plan/ChoosePlanForm";
import Fito from "@/lib/fito";
export default {
  name: "RenewPlan",
  components: {ChoosePlanForm},
  data() {
    return {
      plans : [],
      login: null,
      token: ""
    }
  },
  async mounted() {
    this.login = await Fito().Login.safeCheckLogin()
    if (this.login) this.$emit('login', this.login)

    this.plans = await Fito().Signup.GetPlans();
  },
  methods : {
    async onChoosePlan(plan) {

      const r = await Fito().Signup.RenewSubscriptionToken()

      if (r.token) {
        this.token = r.token;
        localStorage.setItem('SignupToken', r.token)
      }

      this.waitingForPaymentPage = true
      setTimeout(() => {
        let suffix = '?ExtCUserEmail='+this.login.subscriber.email+'&ReturnData='+this.token
        console.log(plan.target_url + suffix)

        if (plan.target_url) window.location.href = plan.target_url + suffix;
      }, 100)
    }
  }
}
</script>
