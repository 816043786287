<template>
  <div>
    <router-link to="/plan/feed" class="back-link">
      <Flexy><img :src="ArrowRightIcon" /> <p>{{t("My Tasks")}}</p></Flexy>
    </router-link>
    <div class="container-m">
      <div class="centered floating-paragraph">
        <p>{{t("These are the tasks you have learned so far. As you progress, more tasks will be added here.")}}</p>
      </div>
      <div class="tasks boxes light">
        <Box v-for="(task, index) in tasks" :key="index">
          <h3>{{task.task}}</h3>
          <p>{{t("Learned on week {0}, day {1}",planWeek(task.day), planWeekday(task.day))}}</p>
        </Box>
      </div>
    </div>
  </div>
</template>

<script>

import Fito from "@/lib/fito";
import t from "@/lib/Locale";
import Box from "@/components/ui/widgets/Box.vue";
import {loadTasks} from "@/lib/DailyQuizUtils";
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import Flexy from "@/components/ui/widgets/Flexy.vue";


export default {
  name: "Tasks",
  components: {Flexy, Box},
  data() {
    return {
      ArrowRightIcon,
      tasks: []
    }
  },
  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')

    await this.getTasks(login.meta, login.daysInPlan)
  },
  methods : {
    t,

    planWeekday(planDay) {
      let weekday = planDay % 7
      if (weekday === 0) weekday = 7
      let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return t(days[weekday - 1])
    },

    planWeek(planDay) {
      return Math.floor(planDay / 7) + 1
    },

    async getTasks(meta, daysInPlan) {
      this.tasks = await loadTasks(daysInPlan)
    }
  }
}
</script>
