import {ApiCall, ApiRequest} from "@/lib/fito/src/lib/Api";
import ApiRoutes from "@/lib/fito/src/lib/ApiRoutes";

export default class Upgrade {

  constructor(fito) {
    this.fito = fito;
  }

  async eatwiseFollowUpPlanGetToken() {
    const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.eatwiseFollowUpPlanGetToken), this.fito.ApiKey, this.fito.token)
    if (r.res === "error") throw r.error
    return r;
  }

  async completeUpgrade(token) {
    const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.completeUpgrade, {token}), this.fito.ApiKey, this.fito.token)
    if (r.res === "error") throw r.error
    return r;
  }

}
