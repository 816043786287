<template>
  <div class="container-m">
    <div class="centered">
      <h2>{{t("Upgrade Complete")}}</h2>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from '@/lib/fito'

export default {
  name: "UpgradeComplete",
  async mounted() {
    await this.completeUpgrade()
  },

  methods : {
    t,
    async completeUpgrade() {
      const upgradeToken = localStorage.getItem('UpgradeToken');
      const {res, token} = await Fito().Upgrade.completeUpgrade(upgradeToken)
      if (res === 'ok') {
        console.log("New token:", token)
        localStorage.setItem("login-token", token);
        localStorage.removeItem('UpgradeToken')


      }
    }
  }
}
</script>
