import Landing from "@/components/views/landing/Landing";
import Contact from "@/components/views/common/Contact";
import Login from "@/components/views/login/Login";
import MyProfile from "@/components/views/plan/MyProfile";
import ExclusivesHome from "@/components/views/plan/exclusives/ExclusivesHome";
import ExclusivesItem from "@/components/views/plan/exclusives/ExclusivesItem";
import Signup from "@/components/views/signup/Signup";
import SignupComplete from "@/components/views/signup/SignupComplete";
import ResetPassword from "@/components/views/login/ResetPassword";
import RenewPlan from "@/components/views/plan/RenewPlan";
import VersionError from "@/components/views/common/VersionError";
import ActivateAccount from "@/components/views/login/ActivateAccount.vue";
import FeedHome from "@/components/views/plan/feed/FeedHome.vue";
import BonusHome from "@/components/views/plan/bonus/BonusHome.vue";
import YogaVideos from "@/components/views/plan/bonus/YogaVideos.vue";
import YogaPractice from "@/components/views/plan/bonus/YogaPractice.vue";
import InfoSlides from "@/components/views/plan/tools/InfoSlides.vue";
import DailyReport from "@/components/views/plan/feed/DailyReport.vue";
import Chat from "@/components/views/plan/Chat.vue";
import RecipesHome from "@/components/views/plan/bonus/Recipes.vue";
import PeriodCalculator from "@/components/views/plan/tools/PeriodCalculator.vue";
import FastingCalculator from "@/components/views/plan/tools/FastingCalculator.vue";
import SpecialVideo from "@/components/views/plan/feed/SpecialVideo.vue";
import ToolsHome from "@/components/views/plan/tools/ToolsHome.vue";
import Tasks from "@/components/views/plan/feed/Tasks.vue";
import UpgradeComplete from "@/components/views/signup/UpgradeComplete.vue";
import Upgrade from "@/components/views/signup/Upgrade.vue";
import IntroductionVideo from "@/components/views/plan/IntroductionVideo.vue";

export default [
    // Landing
    { path: '/', component: Landing, keepAlive: true },
    { path: '/contact', component: Contact, meta: { form: true }, keepAlive: true },
    { path: '/recover-password/:code', component: ResetPassword, meta: { form: true }, keepAlive: true },
    { path: '/activate-account/:code', component: ActivateAccount, meta: { form: true }, keepAlive: true },
    { path: '/old-version', component: VersionError, meta: { form: true }, keepAlive: true },
    { path: '/introduction-video', component: IntroductionVideo, meta: { form: true }, keepAlive: true },

    // Plan
    { path: '/plan/login', component: Login, meta: { form: true }, keepAlive: true },
    { path: '/plan/signup', component: Signup, keepAlive: true },
    { path: '/plan/signup/:code', component: Signup, keepAlive: true },
    { path: '/plan/signup-complete', component: SignupComplete, keepAlive: true },
    { path: '/plan/contact', component: Contact, meta: { form: true }, keepAlive: true },
    { path: '/plan/profile', component: MyProfile, keepAlive: true },
    { path: '/plan/renew', component: RenewPlan,  keepAlive: true },

    // feed
    { path: '/plan/feed', component: FeedHome, meta: { form: true }, keepAlive: true },
    { path: '/plan/quiz/:day', component: DailyReport, meta: { form: true }, keepAlive: true },
    { path: '/plan/special/:video_id', component: SpecialVideo, meta: { form: true }, keepAlive: true },
    { path: '/plan/feed/tasks', component: Tasks, meta: { form: true }, keepAlive: true },

    // chat
    { path: '/plan/chat', component: Chat, meta: { form: true }, keepAlive: true },

    // tools
    { path: '/plan/tools', component: ToolsHome, keepAlive: true },
    { path: '/plan/tools/info-slides', component: InfoSlides, meta: { form: true }, keepAlive: true },
    { path: '/plan/tools/period-calculator', component: PeriodCalculator, meta: { form: true }, keepAlive: true },
    { path: '/plan/tools/fasting-calculator', component: FastingCalculator, meta: { form: true }, keepAlive: true },


    // bonus
    { path: '/plan/bonus', component: BonusHome, keepAlive: true },
    { path: '/plan/bonus/yoga/:list', component: YogaVideos, keepAlive: true },
    { path: '/plan/bonus/yoga/:list/:id', component: YogaPractice, keepAlive: true },
    { path: '/plan/bonus/recipes', component: RecipesHome, meta: { form: true }, keepAlive: true },
    { path: '/plan/bonus/exclusives', component: ExclusivesHome, keepAlive: true },
    { path: '/plan/bonus/exclusives/:id', component: ExclusivesItem, keepAlive: true },

    // account
    { path: '/plan/account', component: MyProfile, meta: { form: true }, keepAlive: true },

  // upgrade
    { path: '/plan/upgrade', component: Upgrade, keepAlive: true },
    { path: '/plan/upgrade-complete', component: UpgradeComplete, keepAlive: true },
]
