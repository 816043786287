<template>
  <div class="calendar">
    <div class="month">
      <div><span @click="prevMonth" v-if="monthOffset>-1">&lt;</span></div>
      <div>{{monthName}}</div>
      <div><span @click="nextMonth" v-if="monthOffset<1">&gt;</span></div>
    </div>
    <div class="week weekdays">
      <div class="day day-name" v-for="(day, ii) in ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']" :key="'dd'+ii">
        {{dayName(ii)}}
      </div>
    </div>
    <div class="week" v-for="(week, i) in weeks" :key="'wk'+i">
      <div :class="dayClass(day)" v-for="day in week" :key="dateStr(day)">
        <div class="day-number">{{day.getDate()}}</div>
      </div>
    </div>
    <div class="legend">
      <div :class="legendClass(item)" v-for="(item, iii) in legend" :key="'ll'+iii">
        <div class="legend-color"></div>
        <div class="legend-text">{{t(item)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
export default {
  name: "CalendarWithHighlights",
  data() {
    return {
      monthOffset: 0
    }
  },
  props: {
    date: {
      type: [Date, String],
      default: () => new Date()
    },
    highlights: {
      type: Array,
      default: () => []
    }
  },
  computed : {
    legend() {
      let items = []
      for (let i = 0; i < this.highlights.length; i++) {
        let item = this.highlights[i];
        if (!items.includes(item.type)) {
          items.push(item.type);
        }
      }
      return items;
    },
    weeks() {
      let d = new Date(this.date);
      if (this.monthOffset) {
        d.setMonth(d.getMonth() + this.monthOffset);
      }


      d.setDate(1);
      let firstDay = d.getDay();
      let daysInMonth = new Date(d.getFullYear(), d.getMonth()+1, 0).getDate();
      let weeks = [];
      let week = [];
      for (let i = 0; i < firstDay; i++) {
        week.push(new Date(d.getFullYear(), d.getMonth(), 1 - firstDay + i));
      }
      for (let i = 1; i <= daysInMonth; i++) {
        week.push(new Date(d.getFullYear(), d.getMonth(), i));
        if (week.length === 7) {
          weeks.push(week);
          week = [];
        }
      }
      if (week.length > 0) {
        let moreDays = 7 - week.length;
        for (let i = 1; i <= moreDays; i++) {
          week.push(new Date(d.getFullYear(), d.getMonth(), daysInMonth + i));
        }
        weeks.push(week);
      }
      return weeks;

    },

    monthName() {
      let names = [
        t('January'),t('February'),t('March'),t('April'),t('May'),t('June'),
        t('July'),t('August'),t('September'),t('October'),t('November'),t('December')
      ];

      let d = new Date(this.date);
      if (this.monthOffset) {
        d.setMonth(d.getMonth() + this.monthOffset);
      }

      return names[d.getMonth()] + ' ' + d.getFullYear();
    }
  },
  methods : {
    t,
    prevMonth() {
      if (this.monthOffset > -1) this.monthOffset--;
    },
    nextMonth() {
      if (this.monthOffset < 1) this.monthOffset++;
    },
    legendClass(item) {
      return ['legend-item', item];
    },
    dateStr(date) {
      date = new Date(date);
      let s = date.getFullYear() + '-'
      if (date.getMonth() < 10) s += '0';
      s += (date.getMonth()+1) + '-'
      if (date.getDate() < 10) s += '0';
      s += date.getDate();

      return s;
    },
    isToday(day) {
      let today = new Date();
      return day.getDate() === today.getDate() && day.getMonth() === today.getMonth() && day.getFullYear() === today.getFullYear();
    },
    isHighlighted(day) {
      return this.highlights.some(highlight => {
        return this.dateStr(highlight.date) === this.dateStr(day);
      });
    },
    highlightClass(day) {
      let classes = ['highlighted'];
      this.highlights.forEach(highlight => {
        if (this.dateStr(highlight.date) === this.dateStr(day)) {
          classes.push(highlight.type);
        }
      });
      return classes.join(' ');
    },
    dayClass(day) {
      let classes = ['day'];
      let d = new Date(this.date);
      if (this.monthOffset) {
        d.setMonth(d.getMonth() + this.monthOffset);
      }
      if (day.getMonth() !== new Date(d).getMonth()) classes.push('other-month');
      if (this.isToday(day)) classes.push('today');
      if (this.isHighlighted(day)) classes.push(this.highlightClass(day));
      return classes.join(' ');
    },
    dayName(day) {
      let names = [
        t('Sun'),t('Mon'),t('Tue'),t('Wed'),t('Thu'),t('Fri'),t('Sat')
      ];
      return names[day];
    },
  }
}
</script>
