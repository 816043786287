<template>
  <div>
    <div class="container-m">
      <Vimeo :path="videoId" />
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Fito from "@/lib/fito";
import Vimeo from "@/components/ui/widgets/Vimeo.vue";

const datedItems = [
  { date: '2023-03-30 00:03:00', title: "פסח", type: "vimeo", media_path: "812937014"}
]

export default {
  name: "FeedHome",
  components: { Vimeo},
  props: ['meta'],

  data() {
    return {
      datedItems,
      inited: false,
      videoId: '',
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin(true)
    if (!login) return this.$router.push('/plan/login')

    this.videoId = this.$route.params.video_id
  },

  computed : {

  },

  methods : {
    t,
  },

}
</script>
