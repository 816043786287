<template>
  <div>
    <div v-if="submittedReport" class="success-bar floating">{{t("Report submitted successfully")}}</div>
    <PlanEnded v-if="planEnded" />
    <div class="container-m" v-if="planStarted">
      <FeedItems :items="items" :show-learned-tasks="true" v-if="!planEnded"
                 :completed-report-days="completedReportDays" :days-in-plan="daysInPlan" :on-quiz-click="onQuizClick" :on-toggle-watch-video="onToggleWatchVideo"
                 :dated-items="datedItems" :watched-videos="watchedVideos" :planStartDate="planStartDateRaw" :debug="showDebug" />
      <p v-if="daysInPlan >= 4 && !planEnded" class="floating-paragraph plan-start">{{t('(Plan Start Date: {0})', planStartDate)}}</p>
    </div>
    <div v-else-if="inited" class="before-plan-start">
      <h1>{{t('The plan will start on {0}',planStartDate)}}</h1>
      <p>{{t("Meanwhile, check our introduction video")}}</p>
      <Vimeo path="806717268" />
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Fito from "@/lib/fito";
import Vimeo from "@/components/ui/widgets/Vimeo.vue";
import FeedItems from "@/components/ui/plan/FeedItems.vue";
import moment from "moment";
import PlanEnded from "@/components/ui/plan/PlanEnded.vue";

const FollowUpPlanId = 8;
const TotalPlanDays = 89;
const unlockFollowupPlan = true;

export default {
  name: "FeedHome",
  components: {PlanEnded, FeedItems, Vimeo},
  props: ['meta'],

  data() {
    return {
      WEBAPP_ENABLED: false,
      datedItems : [],
      inited: false,
      submittedReport: false,
      items : [],
      completedReportDays: [],
      list: null,
      planStarted: false,
      planStartDate: t("next week"),
      planStartDateRaw: '2023-03-12',
      daysInPlan: 0,
      showDebug: false,
      planEnded: false,
      watchedVideos: []
    }
  },

  async mounted() {
    // Redirect to home - WEBAPP IS DISABLED
    if (!this.WEBAPP_ENABLED) return this.$router.push('/');


    const login = await Fito().Login.safeCheckLogin(true)
    if (!login) return this.$router.push('/plan/login')

    if (login.meta['watched-lessons']) {
      this.watchedVideos = login.meta['watched-lessons']
    }

    if (window.location.search.includes('submitted_report')) {
      this.submittedReport = true
      setTimeout(() => {
        this.submittedReport = false
      }, 5000)
    }

    const hasFollowUpPlan = login.plan && login.plan.id === FollowUpPlanId
    this.planEnded = unlockFollowupPlan && login.daysInPlan > TotalPlanDays && !hasFollowUpPlan

    this.setDates(login.subscriber.plan_start_date, login.daysInPlan)

    if (window.location.search === '?ok') this.planStarted = true

    let reports = login.meta.reports || [];
    this.completedReportDays = reports.map(it => it.day*1);

    let feed = login.notifications.sort((a,b) => b.id - a.id)

    this.datedItems = feed.filter((a) => a.fixed_date)
    let nonDated = feed.filter((a) => !a.fixed_date)

    feed = nonDated.map((a) => ({...a, day: a.min_plan_days }))
    this.items = feed;

    this.inited = true;
  },

  computed : {


  },

  methods : {
    t,

    loadMeta() {

    },

    setDates(planStartDate, daysInPlan) {
        let d = planStartDate.replace(/ /g,"T")
        if (d) {
            let subDate = new moment(d).toDate(),
                today = new Date()

            today.setHours(12);
            today.setMinutes(0);
            today.setSeconds(0);

            subDate.setHours(12);
            subDate.setMinutes(0);
            subDate.setSeconds(0);

            if (today - subDate >= 0) this.planStarted = true
            else {
                this.planStartDate = subDate.getDate() + '/' + (subDate.getMonth() + 1) + '/' + subDate.getFullYear()
            }
            this.planStartDateRaw = this.dateStr(subDate)
            this.daysInPlan = daysInPlan
        }
    },

    dateStr(date) {
      date = new Date(date);
      let s = date.getFullYear() + '-'
      if (date.getMonth() < 10) s += '0';
      s += (date.getMonth()+1) + '-'
      if (date.getDate() < 10) s += '0';
      s += date.getDate();

      return s;
    },

    onQuizClick(day) {
      this.$router.push('/plan/quiz/'+day)
    },

    async onToggleWatchVideo(day) {
        if (this.watchedVideos.indexOf(day) === -1) {
          await Fito().RunAction('watch-daily-lesson', {day})
          this.watchedVideos.push(day)
        }
        else {
          await Fito().RunAction('unwatch-daily-lesson', {day})
          this.watchedVideos = this.watchedVideos.filter(it => it !== day)
        }
    }
  },

}
</script>
