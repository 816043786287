<template>
  <div class="item-view daily-report">
    <router-link to="/plan/feed" class="back-link">
      <Flexy><img :src="ArrowRightIcon" />
          <p v-if="isFinalReport">{{t('Final Report')}}</p>
          <p v-else-if="isWeeklyReport">{{t('Weekly Report: week {0}',planWeek(day))}}</p>
          <p v-else>{{t('Daily Report: week {0}, day {1}', planWeek(day), planWeekday(day))}}</p>
      </Flexy>
    </router-link>
      <p class="centered" v-if="!readOnly && !isFinalReport">{{t('Please fill the report in the end of the day once you finished your exercises')}}</p>
      <DailyReportQuestions :questions="validatedQuestions" :on-results="onCompleteReport" v-if="!readOnly" :onAnswer="onAnswer" :is-final="isFinalReport" :day="day" />
      <div v-if="readOnly" class="read-only-report">
        <div class="question" v-for="(q, i) in validatedQuestions" :key="i">
          <div>
            <div v-if="isFinalReport && q.question.indexOf('/') > -1" class="scale-header">
              <span>{{q.question.split('/')[0]}}</span>
              <span>{{q.question.split('/')[1]}}</span>
            </div>
            <span v-else>{{q.question}}</span>
          </div>
          <b>
            <span v-if="isFinalReport && q.question.indexOf('/') > -1" class="read-only-scale">
              <span class="read-only-scale-inner" :style="{width: finalSliderValue(answers[i])+'%'}">{{finalSliderValue(answers[i])}}%</span>
            </span>
            <span v-else>{{answers[i]}}</span>
          </b>
        </div>
      </div>
      <p class="centered" v-if="!readOnly && !isFinalReport">{{t("{0} out of {1} questions answered", questionsAnswered, validatedQuestions.length)}}</p>
      <p class="centered warning" v-if="fillWarning">{{t("Please fill all the questions")}}</p>
      <div class="buttons">
        <button v-if="!readOnly" class="btn btn-primary" @click="onSubmitReport">{{t('Submit Report')}}</button>
      </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import Flexy from "@/components/ui/widgets/Flexy.vue";
import {getDailyQuiz} from "@/lib/DailyQuizUtils";
import DailyReportQuestions from "@/components/forms/plan/DailyReportQuestions.vue";
import Fito from "@/lib/fito";

export default {
  name: "DailyReport",
  components: {DailyReportQuestions, Flexy},
  data() {
    return {
      reportCompleted: false,
      readOnly: false,
      fillWarning: false,
      ArrowRightIcon,
      questions: [],
      answers: [],
      answered : {}
    }
  },
  mounted() {
    this.init()
  },
  computed : {
    questionsAnswered() {
      return Object.values(this.answered).length
    },
    day() {
      return this.$route.params.day || 1
    },
    isWeeklyReport() {
        const d = this.day * 1
        return d % 7 === 0 || d === 6
    },
    isFinalReport() {
      const d = this.day * 1
      return d === 81
    },
    validatedQuestions() {
        const questions = [...this.questions], res = []
        questions.forEach((q) => {
            if (!q['if_row'] || (questions[(q['if_row']*1)-1] && this.answers[(q['if_row']*1)-1] === q['if_val'])) res.push(q)
        })
        return res
    }
  },
  methods : {
    t,

    finalSliderValue(v) {
      console.log({v})
      v *= 1
      v += 3
      v /= 6
      v = parseFloat((1 - v)*100).toFixed(0)
      return v
    },

    planWeekday(planDay) {
        let weekday = planDay % 7
        if (weekday === 0) weekday = 7
        let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        return t(days[weekday - 1])
    },

    planWeek(planDay) {
        return Math.floor(planDay / 7) + 1
    },

    onAnswer(answerIndex, answer) {
      this.$set(this.answered, answerIndex, true)
      this.$set(this.answers, answerIndex, answer)
      if (Object.values(this.answered).length === this.validatedQuestions.length) {
        this.reportCompleted = true
      }
    },

    async init() {
      const login = await Fito().Login.safeCheckLogin()
      if (!login) return this.$router.push('/plan/login')

      const day = this.$route.params.day || 1
      const data = await getDailyQuiz(day*1);
      this.questions = data;

      // if data exists, apply it

      let reports = login.meta.reports || [];
      let report = reports.find(it => it.day === day);
      if (report) {
        this.answers = report.answers.map(it => it.answer);
        this.reportCompleted = true;
        this.readOnly = true;
      }

      this.$nextTick(() => {
        window.scrollTo(0, 0)
      })
    },
    onCompleteReport(answers){
      this.reportCompleted = true;
      console.log({answers})
      this.answers = answers;
    },
    async onSubmitReport() {
      if (this.readOnly) return



      if (!this.reportCompleted && !this.isFinalReport) {
        this.fillWarning = true
          setTimeout(() => {
            this.fillWarning = false
          }, 2000)
        return
      }

      const day = this.$route.params.day || 1

      let pkg = {
        day,
        answers: this.validatedQuestions.map ((q, i) => {
          return {
            question: q.question,
            answer: this.answers[i] ? (this.answers[i].toString().replace(/"/g, "'") || '') : ''
          }
        })
      }

      await Fito().RunAction('submit-report', pkg)
      await this.$router.push('/plan/feed?submitted_report=1')
    }
  },
}
</script>
