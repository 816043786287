<template>
  <div class="container-m boxes light padded">
      <div class="plan-ended">
        <div class="box">
            <h1>{{t("Your Plan has Ended")}}</h1>
            <p>{{t("To continue enjoying the benefits of the plan, you can upgrade to a follow-up plan")}}</p>
            <Btn to="/plan/upgrade">{{t("Upgrade")}}</Btn>
        </div>
      </div>
  </div>
</template>
<script>

import Btn from "@/components/ui/widgets/Btn.vue";
import t from "@/lib/Locale";

export default {
  name: "PlanEnded",
  components: {Btn},
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {
    t,
  },
  mounted() {},
  created() {},
  destroyed() {},
}

</script>
