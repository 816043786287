import {ApiRequest, ApiCall} from '../lib/Api';
import ApiRoutes from "../lib/ApiRoutes";

export default class contact {

    constructor(fito) {
        this.fito = fito
    }

    async SendContactForm(email, phone, name, subject, message) {
        const r = await ApiRequest(ApiCall(this.fito.hostname, ApiRoutes.contact, {email, phone, name, subject, message}), this.fito.ApiKey)
        if (r.res === "error") throw r.error
        return r;
    }
}
