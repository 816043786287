<template>
  <div class="boxes videos vert-padded">
    <Box v-if="showLearnedTasks" link="/plan/feed/tasks" class="promo">
      <h2>{{t("New: My Tasks")}}</h2>
      <p>{{t("Click here to see the tasks you have learned so far.")}}</p>
    </Box>
    <div v-for="(day, i) in shownDays" :key="'day'+i" :data-date="dateStr(dayDate(day))" :data-day="day">

      <div v-if="isWeeklyReport(day) && day < daysInPlan" class="week-separator">{{t('Week {0}', Math.round(day/7)+1)}}</div>

      <Box :subtitle="daysSince(day)" :title="boxTitle(day)" :class="reportClass(day)" @click="() => onQuizClick(day)" v-if="dayHasQuiz(day)">
        <div class="flexy">
          <img :src="reportIcon(day)" />
          <p v-if="reportIsCompleted(day)">{{t(isFinalReport(day)?'Review your final report':(isWeeklyReport(day) ?'Review your weekly report':'Review your daily report'))}}</p>
          <p v-else>
            <tempalte v-if="isFinalReport(day)">{{t('Fill your final report')}}</tempalte>
            <template v-else-if="isWeeklyReport(day)">{{t('Fill your weekly report')}}</template>
            <template v-else>{{t('Fill your daily report')}}</template>
          </p>
        </div>
      </Box>
      <Box :title="parseDays(item.title)" :subtitle="daysSince(day)" v-for="(item, index) in dayItems(day)" :key="index">
        <p v-if="item.content" :class="'box-content' + (expandedContent[index]?' expanded':'')" v-html="item.content"></p>
        <a href="javascript:void(0)" v-if="item.content && item.content.length > 100" @click="toggleExpandContent(index)">[{{t('Read All')}}]</a>
        <Vimeo v-if="item.media_path" :path="item.media_path" />
        <button :class="watchedBtnClass(day)" @click="toggleWatchedVideo(day)" v-if="item.media_path && !item.date">
            <img v-if="dayIsWatched(day)" :src="CheckIcon" />
            {{t('Mark as watched')}}
        </button>
      </Box>
    </div>
    <div>
      <Box :title="t('Plan introduction video')">
        <p>{{t('Watch the video to learn more about the plan')}}</p>
        <Vimeo path="806717268" />
      </Box>
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Box from "@/components/ui/widgets/Box.vue";
import Vimeo from "@/components/ui/widgets/Vimeo.vue";
import CheckIcon from '@/assets/icons/check.svg';
import DailyReportIcon from '@/assets/icons/daily-report.svg';
import DailyReportCompleteIcon from '@/assets/icons/daily-report-complete.svg';
import WeeklyReportIcon from '@/assets/icons/weekly-report.svg';
import WeeklyReportCompleteIcon from '@/assets/icons/weekly-report-complete.svg';
import FinalReportIcon from '@/assets/icons/final-report.svg';
import FinalReportCompleteIcon from '@/assets/icons/final-report-complete.svg';
import {getQuizDays} from "@/lib/DailyQuizUtils";

export default {
  name: "FeedItems",
  components: {Vimeo, Box},
  data() {
    return {
      CheckIcon,
      quizDays: [],
      windowHeight: 0,
      expandedContent: []
    }
  },
  async mounted() {
    this.quizDays = await getQuizDays()
    this.windowHeight = window.innerHeight
    window.addEventListener('scroll', this.setWindowHeight)

    const savedY = localStorage.getItem('feed-scroll-y')
    if (savedY) {
      window.scrollTo(0, savedY*1)
      for (let i = 1; i <= 40; i++) {
          setTimeout(() => window.scrollTo(0, savedY*1), 10*i)
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.setWindowHeight)
  },
  props : {

    showLearnedTasks : {
      type: Boolean,
      default: false
    },

    debug: {
      type: Boolean,
      default: false
    },

    daysInPlan: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    },
    datedItems: {
      type: Array,
      default: () => []
    },
    watchedVideos: {
        type: Array,
        default: () => []
    },
    planStartDate: {
      type: String,
      default: ''
    },
    completedReportDays: {
      type: Array,
      default: () => []
    },
    onQuizClick: {
      type: Function,
      default: () => {}
    },
    onToggleWatchVideo: {
        type: Function,
        default: () => {}
    }
  },

  computed : {
    days() {
      let day = this.daysInPlan
      let d = []
      for (let i = day; i >= 0; i--) {
        d.push(i)
      }
      return d
    },

    shownDays() {
      let totalHeight = 0, d = []
      const elHeight = window.innerWidth > 798 ?
          (window.innerWidth > 1440 ? 755 : 650)
          : 570, days = this.days
      for (let i = 0; i < days.length; i++) {
        if (days[i] < 82) totalHeight += elHeight
        if (totalHeight < this.windowHeight + 3000) {
          d.push(days[i])
        }
        else {
          break
        }
      }
      return d
    }
  },


  methods: {
    t,

    boxTitle(day) {
      if (this.isFinalReport(day)) return t('Final Report')
      return this.isWeeklyReport(day)?t('Weekly Report: week {0}', Math.round(day/7)):t('Daily Report: week {0}, day {1}', this.planWeek(day), this.planWeekday(day))
    },

    watchedBtnClass(day) {
        return 'btn watched-btn ' + (this.dayIsWatched(day) ? 'watched' : 'not-watched')
    },

    dayIsWatched(day) {
        return this.watchedVideos.includes(day)
    },

    toggleWatchedVideo(day) {
        this.onToggleWatchVideo(day)
    },

    reportIcon(day) {
        if (this.reportIsCompleted(day)) {
            return this.isFinalReport(day)? FinalReportCompleteIcon : (this.isWeeklyReport(day) ? WeeklyReportCompleteIcon : DailyReportCompleteIcon)
        }
        return this.isFinalReport(day) ? FinalReportIcon : (this.isWeeklyReport(day) ? WeeklyReportIcon : DailyReportIcon)
    },

    setWindowHeight() {
      let y = window.scrollY, h = window.innerHeight
      if (y + h >= this.windowHeight) this.windowHeight = y + h

      // save y to local storage
      localStorage.setItem('feed-scroll-y', y)
    },

    daysSince(day) {
      let d = new Date(this.planStartDate)
      d.setDate(d.getDate() + (day - 1))
        let cur = new Date(this.planStartDate)
        cur.setDate(cur.getDate() + (this.daysInPlan - 1))
      let diff = Math.round((cur - d) / (1000 * 60 * 60 * 24))

      if (diff === 0) return t('Today')
      if (diff === 1) return t('Yesterday')
      if (diff === 2) return t('2 days ago')
      if (diff < 7) return t('{0} days ago', diff)
      if (diff < 14) return t('Last week')
      return t('{0} weeks ago', Math.round(diff / 7))
    },

    dateStr(date) {
      date = new Date(date);
      let s = date.getFullYear() + '-'
      if (date.getMonth() < 10) s += '0';
      s += (date.getMonth()+1) + '-'
      if (date.getDate() < 10) s += '0';
      s += date.getDate();

      return s;
    },

    dayDate(day) {
      let d = new Date(this.planStartDate)
      d.setDate(d.getDate() + (day - 1))
      return d
    },

    dayItems(day) {
      let items = this.items.filter(it => it.day === day)
      let datedItems = this.datedItems.filter(it => this.dateStr(this.dayDate(day)) === this.dateStr(it.fixed_date.replace(/ /g,"T")))
      return items.concat(datedItems)
    },

    toggleExpandContent(index) {
      this.$set(this.expandedContent, index, !this.expandedContent[index])
    },

    isFinalReport(day) {
      return day === 81
    },

    isWeeklyReport(day) {
      return day % 7 === 0 || day === 6
    },

    reportClass(day) {
      if (this.completedReportDays.includes(day)) return 'report completed';
      return 'report';
    },

    reportIsCompleted(day) {
      return this.completedReportDays.includes(day);
    },

    dayHasQuiz(day) {
      return this.quizDays.includes(day) && day > 0;
    },

      planWeekday(planDay) {
        let weekday = planDay % 7
        if (weekday === 0) weekday = 7
        let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        return t(days[weekday - 1])
    },

    planWeek(planDay) {
        return Math.floor(planDay / 7) + 1
    },

    parseDays(str) {
      let day = str.match(/\d+:/g)
      if (day) {
        day = day[0].replace(':', '')
        day = t("Week {0}, Day {1}", this.planWeek(day), this.planWeekday(day))
        str = day +' - '+ str.substring(str.indexOf(':') + 1)
      }
      return str
    }
  }
}
</script>
