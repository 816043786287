<template>
  <div class="vimeo-container">
    <iframe v-if="path" :src="iframeSrc" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="פתיח מתחילים - מירן"></iframe>
  </div>
</template>

<script>
export default {
  name: "Vimeo",
  props : {
    path : {type: String},
    isEvent: { type: Boolean, default: false}
  },
    computed : {
      iframeSrc() {
        if (this.isEvent) return 'https://vimeo.com/event/'+this.path+'/embed'
        else return 'https://player.vimeo.com/video/'+this.path+"?h=9eb55907fb"
      }
    }
}
</script>
