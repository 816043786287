<template>
  <div>
    <ExpiredForm v-if="expired" />
    <div v-if="suspended">
      <div class="boxes light">
        <Box :title="t('Account Suspended')" link="/contact"><p>{{suspended}}</p></Box>
      </div>
    </div>
  <div class="view-content container" v-if="!expired && !suspended">
      <h3>{{t('Exclusive benefits')}}</h3>
      <div class="boxes halves">
        <div class="box" v-for="(item, index) in restOfItems" :key="index">
        <router-link :to="'/plan/bonus/exclusives/'+item.id">
          <div class="box-thumb" :style="{backgroundImage: `url(${item.thumbnail})`}"></div>
          <h2>{{item.title}}</h2>
          <p>{{item.headline}}</p>
        </router-link>
        </div>
      </div>
  </div>
  </div>
</template>

<script>

import Fito from '@/lib/fito';

import t from "@/lib/Locale";
import Box from "@/components/ui/widgets/Box";
import ExpiredForm from "@/components/forms/ExpiredForm";

const ITEMS_IN_TOP_SLIDE = 2;

export default {

  name: "ExclusivesHome",
  components: {ExpiredForm, Box, },

  data : function() {
    return {
      items: [],
      expired: false,
      suspended: false,
      locked: false,
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')


    if (login.status === "expired") this.expired = true;
    if (Fito().suspended) { this.suspended = Fito().suspended }

    this.items = await Fito().Plan.getExclusives()
    this.locked = false
  },

  computed : {
    topItems() {
      return this.items && this.items.length ? this.items.slice(0, ITEMS_IN_TOP_SLIDE) : []
    },
    restOfItems() {
      return this.items
    }
  },

  methods: {
    t,
  },

}
</script>
