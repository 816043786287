<template>
  <div class="chat">

  </div>
</template>

<script>

import Fito from "@/lib/fito";

export default {
  name: "Chat",
  data() {
    return {

    }
  },
  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    let startDate = login.subscriber ? login.subscriber.plan_start_date.replace(/ /g,"T") : null
    if (!login) return this.$router.push('/plan/login')
    let visitor = {
      name: login.subscriber.first_name + ' ' + login.subscriber.last_name,
      Phone: login.subscriber.phone.toString(),
      email: login.subscriber.email,
    }

    if (visitor.Phone[0] === '0') {
      visitor.Phone = visitor.phone.substring(1)
    }
    if (visitor.Phone[0] === '5') visitor.Phone = '972' + visitor.Phone

    if (startDate) visitor.startDate = startDate

    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "g7fhjccm",
      name: visitor.name,
      email: visitor.email,
      phone: visitor.Phone,
      start_date: Math.floor(new Date(visitor.startDate).getTime() / 1000),
    };
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/g7fhjccm';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

    setTimeout(() => {
      window.Intercom('boot', window.intercomSettings)
      window.Intercom('show')
    }, 1000)
  },
  beforeDestroy() {
    console.log("Dest", window.Intercom)
    if (window.Intercom) window.Intercom('shutdown')

  }
}
</script>
