<template>
  <div>
    <div class="boxes light purple">
      <Box :title="t('Info Slides')" :thumb="ChartIcon" link="/plan/tools/info-slides">{{t("Information items to help you along the course")}}</Box>
      <Box :title="t('Period Calculator')" :thumb="PeriodIcon" link="/plan/tools/period-calculator" v-if="daysInPlan >= 10">{{t("Estimate your next ovulation and period")}}</Box>
      <Box :title="t('Fasting Calculator')" :thumb="FastingIcon" link="/plan/tools/fasting-calculator" v-if="daysInPlan >= 19">{{t("Manage your fasting and watch your fasting history")}}</Box>
    </div>
  </div>
</template>

<script>

import t from '@/lib/Locale';
import Box from "@/components/ui/widgets/Box";
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import ExclusivesIcon from '@/assets/icons/menu/exclusives.svg';
import PeriodIcon from '@/assets/icons/menu/woman.svg';
import RecipesIcon from '@/assets/icons/menu/recipes.svg';
import FastingIcon from '@/assets/icons/menu/yoga.svg';
import ChartIcon from '@/assets/icons/menu/presentation-chart.svg';

import Fito from "@/lib/fito";


export default {
  name: "ToolsHome",
  props: ["meta"],
  components: { Box},
  data() {
    return {
      ArrowRightIcon, ExclusivesIcon, RecipesIcon, PeriodIcon, FastingIcon, ChartIcon,
      lists: [],
      daysInPlan: 0,
    }
  },

  async mounted() {
    const login = await Fito().Login.safeCheckLogin()
    if (!login) return this.$router.push('/plan/login')

    this.daysInPlan = login.daysInPlan

  },

  methods : {
    t,
  },

  watch : {
  }

}
</script>
