<template>
    <div class="container-m padded">
        <div class="box">
            <div class="centered">
                <h3>{{t('Passing you to upgrade form')}}</h3>
                <PoweredBy />
            </div>
        </div>
    </div>
</template>

<script>
import t from '@/lib/Locale';
import PoweredBy from "@/components/ui/widgets/PoweredBy.vue";
import Fito from "@/lib/fito";
const paymentFormUrl = 'https://secure.cardcom.solutions/e/hsPBLlqsMEGAHN5Blz8ySg';

export default {
  name: "Upgrade",
  props: ["meta"],
  components: {PoweredBy, },
  data() {
    return {
      paymentFormUrl,
    }
  },

  async mounted() {
      const r = await Fito().Upgrade.eatwiseFollowUpPlanGetToken()

      if (r.token) {
          this.token = r.token;
          localStorage.setItem('UpgradeToken', r.token)
          console.log(Fito())
          if (r.url) {
              window.location.href = r.url+'&CardOwnerName=&CardOwnerEmail='
          }
      }
      else {
          if (r.error === 'already-upgraded') {
              return this.$router.push('/plan/feed')
          }
          else
          console.error("No token received")
      }
  },

  methods : {
    t,
  }

}
</script>
