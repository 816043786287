<template>
  <div>
    <router-link to="/plan/tools" class="back-link">
      <Flexy><img :src="ArrowRightIcon" /> <p>{{t('Period Calculator')}}</p></Flexy>
    </router-link>
    <div class="container-m">
      <div class="boxes light">
        <div class="box" v-if="results">
          <h3>{{t('Calculator Results')}}</h3>
          <p>{{t('Your next period will be on')}} {{parseDate(results.nextPeriod)}}</p>
          <p>{{t('Your ovulation will be on')}} {{parseDate(results.ovulation)}}</p>

          <CalendarWithHighlights :date="lastPeriod" :highlights="highlightedDates" />

          <div class="inline-buttons">
            <button class="btn" @click="calculateAgain">{{t('Calculate Again')}}</button>
            <button class="btn" @click="saveResults" v-if="!hasSaved">{{t('Save Results')}}</button>
          </div>
          <div v-if="saved" class="success-bar">
            <h3>{{t("Saved Results")}}</h3>
          </div>
        </div>
        <div class="box" v-else>
          <h3>{{t('Period Calculator')}}</h3>
          <p>{{t('When was your last period?')}}</p>
          <input type="date" v-model="lastPeriod" />
          <p>{{t('How many days is your period on average?')}}</p>
          <input type="number" v-model="periodDays" />
          <div class="buttons">
            <button class="btn" @click="calculate" v-if="lastPeriod">{{t('Calculate')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale';
import Fito from "@/lib/fito";
import CloseIcon from "@/assets/icons/x.svg"
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import Flexy from "@/components/ui/widgets/Flexy.vue";
import CalendarWithHighlights from "@/components/ui/plan/CalendarWithHighlights.vue";

export default {
  name: "PeriodCalculator",
  components: {CalendarWithHighlights, Flexy},
  data() {
    return {
      CloseIcon, ArrowRightIcon,
      lastPeriod: null,
      periodDays: 28,
      results: null,
      daysBefore: 3,
      saved: false,
      hasSaved: false,
    }
  },
  async mounted() {
    const login = await Fito().Login.safeCheckLogin();
    if (!login) await this.$router.push('/plan/login');

    const savedData = login.meta['period-dates']
    if (savedData) {
      this.hasSaved = true;
      this.lastPeriod = savedData.last_period;
      this.results = {
        nextPeriod: new Date(savedData.next_period),
        ovulation: new Date(savedData.ovulation),
      }
    }

  },
  computed: {
    highlightedDates() {
      let days = [
        {type: 'ovulation', date: this.results.ovulation},
        {type: 'period', date: this.lastPeriod},
        {type: 'period', date: this.results.nextPeriod},
      ]

      let fertilityStart = new Date(this.results.ovulation);
      fertilityStart.setDate(fertilityStart.getDate() - 5);
      days.push({type: 'fertility', date: new Date(fertilityStart)});
      for (let i = 0; i < 6; i++) {
        fertilityStart.setDate(fertilityStart.getDate() + 1);
        if (this.parseDate(fertilityStart) !== this.parseDate(this.results.ovulation)) {
          days.push({type: 'fertility', date: new Date(fertilityStart)});
        }
      }

      let nextPeriodDate = new Date(this.results.nextPeriod);
      for (let i = 0; i < 4; i++) {
        nextPeriodDate.setDate(nextPeriodDate.getDate() + 1);
        days.push({type: 'next period', date: new Date(nextPeriodDate)});
      }


      return days;
    }
  },
  methods: {
    t,
    calculateAgain() {
      this.results = null;
      this.hasSaved = false;
    },
    dateStr(date) {
      date = new Date(date);
      let s = date.getFullYear() + '-'
      if (date.getMonth() < 10) s += '0';
      s += (date.getMonth()+1) + '-'
      if (date.getDate() < 10) s += '0';
      s += date.getDate();

      return s;
    },
    parseDate(date) {
      let d = new Date(date);
      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
    },
    calculate() {
      const lastPeriod = new Date(this.lastPeriod);
      const nextPeriod = new Date(lastPeriod);
      nextPeriod.setDate(nextPeriod.getDate() + this.periodDays*1);
      const ovulation = new Date(lastPeriod);
      ovulation.setDate(ovulation.getDate() + (this.periodDays / 2));
      this.results = {
        nextPeriod: nextPeriod,
        ovulation: ovulation
      }

      this.setReminder()
    },

    async saveResults() {
      await Fito().RunAction('set-period', {
        last_period: this.dateStr(this.lastPeriod),
        ovulation: this.dateStr(this.results.ovulation),
        next_period: this.dateStr(this.results.nextPeriod),
      })
      this.saved = true;
      this.hasSaved = true;

      setTimeout(() => {
        this.saved = false;
      }, 3000);
    },

    setReminder() {
      const lastPeriod = new Date(this.lastPeriod);
      const nextPeriod = new Date(lastPeriod);
      nextPeriod.setDate(nextPeriod.getDate() + this.periodDays*1);
      const reminderDate = new Date(nextPeriod);
      reminderDate.setDate(reminderDate.getDate() - this.daysBefore);
      localStorage.setItem('periodReminder', reminderDate.getTime().toString());
    }
  }
}
</script>
