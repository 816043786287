<template>
  <div>
    <div class="boxes quiz-questions">
      <div v-for="(q, i) in questions" :key="i" class="box">
        <div v-if="isFinal && q.question.indexOf('/') > -1" class="scale-question">
          <div class="scale-header">
            <span>{{questionPart(q.question, 0)}}</span>
            <span>{{questionPart(q.question, 1)}}</span>
          </div>
          <div class="scale-body">
            <RangeSlider v-model="answers[i]" :min="-3" :max="3" :step="1" @change="(v) => onAnswer(i, v)" />
          </div>
        </div>
        <p v-else>{{q.question}}</p>

        <div v-if="q.options.length > 6 && day < 15" class="btn-group scale-header">
            <span>{{t("A little")}}</span>
            <span>{{t("A lot")}}</span>
        </div>
        <div class="btn-group">
          <button :class="btnClass(i, ii)" v-for="(a, ii) in q.options" :key="ii" @click="pickAnswer(i, ii)">{{a}}</button>
        </div>
        <div class="question-details" v-if="showDetailsFor(i)">
          <textarea :value="q.details" :placeholder="t('Please add details')" @input="(e) => updateDetails(i, e.target.value)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import t from '@/lib/Locale'
import RangeSlider from "vue-range-slider"
import 'vue-range-slider/dist/vue-range-slider.css'



export default {
  name: "DailyReportQuestions",
  components: {RangeSlider},
  props: {
    questions: {type: Array },
    onResults: {type: Function },
    onAnswer: {type: Function },
    isFinal: {type: Boolean, default: false },
    day: {type: Number, default: 1 },
  },
  data() {
    return {
      answers : []
    }
  },
  computed : {
    questionsWithOptions() {
      return this.questions.filter(it => it.options.length > 0)
    }
  },
  methods : {
    t,
    questionPart(question, part) {
      return question.split('/')[part]
    },

    pickAnswer(questionIndex, answerIndex) {
      this.$set(this.answers, questionIndex, answerIndex);

      let ok = this.answers.filter(it => !isNaN(it)).length
      if (ok === this.questions.length) { // answered everything
        this.finishQuiz()
      }
      this.onAnswer(questionIndex, this.questions[questionIndex].options[answerIndex])
    },

    updateDetails(questionIndex, details) {
      this.$set(this.questions[questionIndex], 'details', details)
      let ok = this.answers.filter(it => !isNaN(it)).length
      this.onAnswer(questionIndex, details.replace(/"/g, "'"))
      if (ok === this.questionsWithOptions.length) { // answered everything
        this.finishQuiz()
      }
    },

    showDetailsFor(questionIndex) {
      if (this.isFinal && this.questions[questionIndex].question.indexOf('/') > -1) {
        return false
      }
      return this.questions[questionIndex].options.length === 0 ||
            (this.questions[questionIndex]['free_text_on'] && this.questions[questionIndex].options[this.answers[questionIndex]] === this.questions[questionIndex]['free_text_on'])
    },

    finishQuiz() {
      let answers = [], a
      for (let q = 0; q < this.questions.length; q++) {
        a = this.questions[q].options ? this.questions[q].options[this.answers[q]] : ''
        if (this.questions[q].details) {
          a += ': '+this.questions[q].details
        }
        answers.push(a)
      }
      this.onResults(answers)
    },

    btnClass(questionIndex, answerIndex) {
      return 'btn' + (this.answers[questionIndex] === answerIndex ? ' active':'');
    }
  }
}
</script>
