<template>
  <div class="container-m">
    <div class="centered">
      <div class="header-logo small"><img :src="Logo" /></div>
      <h2>{{t('Welcome Back')}}</h2>
      <p>{{t('Enter your details to sign in')}}</p>
    </div>

    <div v-if="expired" class="expired">
      <h3>{{t("Your account has expired")}}</h3>
      <p>{{t("Login with another user, or contact us")}}</p>
    </div>

    <div class="panel raw">
      <div class="info-box" v-if="recoverySent">
        {{(phoneRecovery?
          t('A link to recover your password was sent to your phone number'):
          t('A link to recover your password was sent to your e-mail address'))}}
      </div>
      <LoginForm v-if="!forgot && !recoverySent" :on-submit="onLogin" :on-forgot="onToggleForgot" :error="error" :stage="loginStage" />
      <ForgotForm v-if="forgot && !recoverySent" :on-submit="onForgot" :error="error" />
      <div class="centered" v-if="!recoverySent && loginStage !== 'activation'">
        <p>{{t('Not registered yet?')}} <router-link to="/plan/signup">{{t('Sign Up Now')}}</router-link></p>
      </div>
    </div>
    <PoweredBy />
  </div>

</template>

<script>
import t from "@/lib/Locale";
import LoginForm from "@/components/forms/plan/LoginForm";
import ForgotForm from "@/components/forms/plan/ForgotForm";
import Fito from '@/lib/fito';
import Logo from '@/assets/icons/lotus-white.png';
import PoweredBy from "@/components/ui/widgets/PoweredBy.vue";


export default {
  name: "Login",
  components: {PoweredBy, LoginForm, ForgotForm },

  data : function() {
    return {
      WEBAPP_ENABLED: false,
      Logo,
      error: '',
      loginStage: 'email',
      forgot : false,
      phoneRecovery: false,
      trying: false,
      expired: false,
      recoverySent: false
    }
  },

  mounted() {
    if (Fito().expired) this.expired = true

    // redirect to home - WEBAPP IS DISABLED
    if (!this.WEBAPP_ENABLED) this.$router.push('/');
  },

  methods: {
    t,
    async onLogin(email, password = null, externalId = '') {
      if (this.trying) return false;
      this.trying = true;
      this.error = '';
      let res

      try {
        switch (this.loginStage) {

          case 'password':

            res = await Fito().Login.Login(email, password, externalId);
            if (res.token) {
              this.$router.push('/plan/feed');
              this.$emit('retry-login',res);
              this.$emit('login-success',res);
              localStorage.setItem("login-token", res.token);
              this.trying = false;
            }
            else {
              if (res.res === 'notfound') {
                this.error = t("Subscriber Not found for this email/password combination")
                if (res.inactiveUntil) {
                  const d = new Date(res.inactiveUntil)
                  this.error = t("Frozen until {0}", d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear())
                }
              }
              this.trying = false;
              console.log("Not found...")
            }
            break

          case 'email':
          default:

            if (externalId) {
              res = await Fito().Login.Login(email, password, externalId);
              if (res.token) {
                this.$router.push('/plan/feed');
                this.$emit('retry-login',res);
                this.$emit('login-success',res);
                localStorage.setItem("login-token", res.token);
                this.trying = false;
                return
              }
            }

            res = await Fito().Login.CheckEmail(email);
            if (res.res === 'notfound') {
              this.error = t("Subscriber Not found for this email")
              this.trying = false;
              return
            }
            else if (res.res === 'no-pass') {
              await Fito().Login.SendActivationCode(email)
              this.loginStage = 'activation'
              this.trying = false
              return
            }

            this.loginStage = 'password';
            this.trying = false;
        }
      }
      catch (error) {
        this.error = error;
        this.trying = false;
      }
    },

    async onForgot(identifier) {
      try {
        this.error = '';
        const res = await Fito().Login.ForgotPassword(identifier)
        if (res.phone) this.phoneRecovery = true;
        this.recoverySent = true;
      } catch (e) {
        this.error = e;
      }
    },

    onToggleForgot() {
      this.forgot = !this.forgot;
    }
  },
}
</script>
